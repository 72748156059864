/* Define Custom Media queries */
@custom-media --mq-mobile-only (max-width: 767px);
@custom-media --mq-mobile-landscape (min-width: 500px) and (max-width: 767px);
@custom-media --mq-mobile-up (min-width: 0);
@custom-media --mq-tablet (min-width: 768px);
@custom-media --mq-tablet-portrait (min-width: 768px) and (max-width: 1023);
@custom-media --mq-tablet-only (min-width: 768px) and (max-width: 1024px);
@custom-media --mq-tablet-down (max-width: 1024px);
@custom-media --mq-desktop (min-width: 1024px);
@custom-media --mq-hd (min-width: 1200px);
@custom-media --mq-hdx (min-width: 1440px);
@custom-media --mq-hdxx (min-width: 1920px);

/* Define global CSS variables inside :root */
:root {
  --grid-gutter: 10px;
  --content-max-width: 1920px;
  --padding-site-mobile: var(--grid-gutter);
  --padding-site-desktop: calc(3 * var(--grid-gutter));

  --color-black: #1b1b1a;
  --color-gray: #949493;
  --color-white: #ffffff;
  --color-primary: khaki;
  --color-green: #6EABAF;
  --color-dark-green: #4D7C80; 
  --color-petroleum: #26484A;
  --color-dark-orange: #bf5b00; 
  --color-orange: #ed7100;
  --color-yellow: #e9c258;
  --color-light-gray: #e5e5e5;
  --color-border-gray: #e6e6e6;

  --color-green-10: rgba(110, 171, 175, 0.1);
  --color-petroleum-5: rgba(38, 72, 74, 0.05);
  --color-petroleum-10: rgba(38, 72, 74, 0.1);
  --color-petroleum-30: rgba(38, 72, 74, 0.3);
  --color-petroleum-50: rgba(38, 72, 74, 0.5);

  --color-black-40: #949493;

  --font-hill: "FibraOneAlt", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --font-size-small: 12px;
  --line-height-small: 18px;

  --font-size-medium: 14px;
  --line-height-medium: 18px;

  --font-size-body: 16px;
  --line-height-body: 24px;

  --font-size-header-small: 24px;
  --line-height-small-header: 32px;

  --font-size-header-medium: 32px;
  --line-height-medium-header: 40px;

  --font-size-header-large: 48px;
  --line-height-large-header: 54px;

  --font-size-header-small-mobile: 1.15rem;
  --font-size-header-medium-mobile: 1.44rem;
  --font-size-header-large-mobile: 2rem;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-demibold: 600;
  --font-weight-bold: 700;

  --font-lineheight-headers: 1em;

  --section-padding-large: 100px;
  --section-padding-medium: 50px;
  --section-padding-small: 20px;
}
